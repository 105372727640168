import update from 'immutability-helper'
import _ from 'lodash'

const initialState = {}

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_WORD_STATUS_COVERAGE': {
      return update(state, { wordStatusCoverage: { $set: action.payload } })
    }
    case 'UPDATE_WORD_STATUS_COVERAGE': {
      const { oldStatus, newStatus } = action.payload
      const newWordStatusCoverage = [...state.wordStatusCoverage]

      if (oldStatus !== undefined) {
        const oldStatusIndex = _.findIndex(state.wordStatusCoverage, { status: oldStatus })
        newWordStatusCoverage[oldStatusIndex].total -= 1
      }
      if (newStatus !== undefined) {
        const newStatusIndex = _.findIndex(state.wordStatusCoverage, { status: newStatus })
        newWordStatusCoverage[newStatusIndex].total += 1
      }
      return update(state, { wordStatusCoverage: { $set: newWordStatusCoverage } })
    }
    default:
      return state
  }
}

export default dataReducer
