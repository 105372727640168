import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Grid, Hidden, IconButton, Toolbar } from '@mui/material'
import Link from 'components/commons/Link'
import UserPseudo from 'components/layout/UserPseudo'
import Image from "components/commons/Image";

function Header({
  classes,
  handleDrawerToggle
}) {
  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          size="large">
          <MenuIcon />
        </IconButton>
        <Hidden smUp>
          <div className={'logo mobile'}>
            <Link href="/">
              <Image src="/images/logo.svg" alt={'logo'} width={135} height={43} />
            </Link>
          </div>
        </Hidden>
        <Hidden only={['xs']}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" className={'header'}>
            <Grid sm={3} item className={'logo'}>
              <Link href="/">
                <Image src="/images/logo.svg" alt={'logo'} width={135} height={43} />
              </Link>
            </Grid>
            <Grid item>
              <div className="user">
                <div className="language">
                  <Image src="/images/flags/en.svg" alt={'en'} height={28} width={28} />
                </div>
                <div className="icon">
                  <Image src="/images/account.svg" alt={'account'} height={28} width={24} />
                </div>
                <div className="pseudo">
                  <UserPseudo />
                </div>
              </div>
            </Grid>
          </Grid>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

export default Header
