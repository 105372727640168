import React from 'react'
import { isLoggedIn } from 'js/security'
import { Button } from '@mui/material'
import ButtonLink from 'components/commons/ButtonLink'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { toggleModal } from 'store/actions/modalActions'
import { useDispatch, useSelector } from 'react-redux'

export default function UserPseudo({eventClick}) {
  const currentUser = useSelector((state) => state.currentUser)
  const dispatch = useDispatch()

  if (isLoggedIn(currentUser)) {
    return (
      <Button
        disableElevation
        variant={'outlined'}
        href={'/account'}
        className={'snow'}
        component={ButtonLink}
        disableRipple
        endIcon={<ChevronRightIcon />}
      >
        {currentUser.pseudo}
      </Button>
    )
  }
  return (
    <Button
      disableElevation
      variant={'outlined'}
      onClick={() => dispatch(toggleModal('login'))}
      component={ButtonLink}
      href={'/'}
      disableRipple
      className={'snow'}
      endIcon={<ChevronRightIcon />}
    >
      Log in
    </Button>
  )
}
