import _ from 'lodash'
import dayjs from 'dayjs'

export const sleep = (delay) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay)
  })

export const getSlugId = (slug) => {
  if (slug && slug['@id']) {
    const slugId = slug['@id'].split('/')
    return slugId.pop()
  } else if (slug.indexOf('/') > -1) {
    const slugId = slug.split('/')
    return slugId.pop()
  } else {
    return slug
  }
}

/**
 * Parse timestamp
 * @param value string
 * @param format string
 */
export const getDateTimeFormatted = (value, format = 'DD/MM/YYYY - HH:mm') => {
  const date = dayjs(value ? value * 1000 : undefined)
  return date.isValid() ? date.format(format) : ''
}

export function getDateTime(value, format = 'DD/MM/YYYY - HH:mm') {
  const date = dayjs(value).format(format)
  return date === 'Invalid date' ? '' : date
}

export const match = (text, query) => {
  if (!query) {
    return []
  }
  const results = []
  const trimmedQuery = query.trim().toLowerCase()
  const textLower = text.toLowerCase()
  const queryLength = trimmedQuery.length
  let indexOf = textLower.indexOf(trimmedQuery)
  while (indexOf > -1) {
    results.push([indexOf, indexOf + queryLength])
    indexOf = textLower.indexOf(query, indexOf + queryLength)
  }
  return results
}

export function findClosest(array, key) {
  return array.reduce((best, current) => {
    return current >= key && (!best || current < best) ? current : best
  }, _.max(array))
}

export const contentToBlobDownload = (content, fileName) => {
  const location = URL.createObjectURL(content)
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = location
  a.download = fileName
  a.target = '_blank'
  a.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(location)
    document.body.removeChild(a)
  }, 0)
}

export const removeNull = (object) => _.omitBy(object, _.isEmpty)

export const objectToArrayObject = (object) => Object.entries(object).map(([key, val]) => ({ [key]: val }))

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object, base) => {
  function changes(object, base) {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }

  return changes(object, base)
}

export const percentage = (partial, total) => {
  return Math.floor((partial / total) * 100)
}
