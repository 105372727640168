import update from 'immutability-helper'

const initialState = {
  login: { open: false }
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL': {
      return update(state, { [action.modal]: { $set: { open: !state[action.modal].open } } })
    }
    default:
      return state
  }
}

export default modalReducer
