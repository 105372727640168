import { combineReducers } from 'redux'
import noty from './notyReducer'
import modal from './modalReducer'
import currentUser from './currentUserReducer'
import wordList from './wordListReducer'
import data from './dataReducer'

const appReducers = combineReducers({
  currentUser,
  noty,
  modal,
  wordList,
  data
})

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined
  }
  return appReducers(state, action)
}
