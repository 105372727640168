import {
  API_addUserWordList,
  API_deleteUserWordList,
  API_getUserWordLists,
  Api_updateUserWordList
} from 'js/api/UserWordLists'

export const getUserWordLists = () => {
  return (dispatch) =>
    API_getUserWordLists()
      .then((wordList) => dispatch(setWordLists(wordList)))
      .catch((error) => Promise.reject(error))
}

export const setWordLists = (wordList) => (dispatch) =>
  dispatch({
    type: 'SET_WORD_LISTS',
    payload: wordList
  })

export const addWordList = (wordList) => {
  return (dispatch) =>
    API_addUserWordList(wordList)
      .then((newWordList) => {
        dispatch({
          type: 'ADD_WORD_LIST',
          payload: newWordList
        })
        return newWordList
      })
      .catch((error) => Promise.reject(error))
}

export const updateWordListStatus = (wordList) => {
  return (dispatch) =>
    Api_updateUserWordList(wordList)
      .then((newWordList) => {
        dispatch({
          type: 'UPDATE_WORD_LIST_STATUS',
          payload: newWordList
        })
      })
      .catch((error) => Promise.reject(error))
}

export const deleteWordList = (wordList) => {
  return (dispatch) => {
    API_deleteUserWordList(wordList)
      .then(() => {
        dispatch({
          type: 'DELETE_WORD_LIST',
          payload: wordList
        })
      })
      .catch((error) => Promise.reject(error))
  }
}
