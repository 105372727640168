import React, { useEffect, useState } from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import theme from 'config/theme'
import CssBaseline from '@mui/material/CssBaseline'
import NotyArea from 'components/layout/NotyArea'
import Layout from 'components/layout/Layout'
import { checkToken, getToken } from 'js/security'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'store/actions/currentUserActions'
import { CacheProvider } from '@emotion/react'
import createEmotionCache from '../js/createEmotionCache'
import { getUserWordLists } from 'store/actions/wordListActions'

const clientSideEmotionCache = createEmotionCache()

function App(props) {
  const { Component, pageProps, err, router, emotionCache = clientSideEmotionCache } = props

  const dispatch = useDispatch()

  const checkUser = async () => {
    const token = getToken()
    return new Promise(async (resolve) => {
      if (token) {
        try {
          await dispatch(getCurrentUser())
          await dispatch(getUserWordLists())
        }catch (e){
        }

        resolve()
      } else {
        resolve(true)
      }
    })
  }

  const [loading, setLoading] = useState(true)

  const init = async () => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
    await checkUser()
  }

  useEffect(() => {
    init().then(() => setLoading(false))
    checkToken(45)
  }, [])

  //Refresh app when luser change
  const currentUser = useSelector((state) => state.currentUser)

  return (
    <CacheProvider value={emotionCache}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotyArea />
          {!loading && (
            <Layout user={currentUser['@id']}>
              <Component {...pageProps} />
            </Layout>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  )
}

export default App
