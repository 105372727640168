import _ from 'lodash'
import SetInterval from 'set-interval'
import jwt_decode from 'jwt-decode'
import { apiCall, eject } from 'js/api'
import dayjs from 'dayjs'

export const setToken = (type, value) => {
  localStorage.setItem(type, value)
}

export const deleteToken = (type) => {
  localStorage.removeItem(type)
}

export const getToken = (type = 'token') => (typeof window !== 'undefined' && localStorage.getItem(type)) || false

export const isPublic = (uri, method) => {

  const slugUrl = uri.split('/')[0]
  switch (slugUrl) {
    case 'login':
      return true
    case 'users':
      switch(method){
        case 'POST':
          return true
        default:
        return false
      }
    default:
      return false
  }
}

export const isLoggedIn = (user) => {
  return !_.isEmpty(user)
}

export const refreshToken = async () => {
  const refreshToken = getToken('refresh_token')
  apiCall({
    uri: 'token/refresh',
    method: 'POST',
    params: { refresh_token: refreshToken },
    raw: true,
    init: true
  })
    .then(async (response) => {
      if (response.status === 200) {
        await setToken('token', response.data.token)
      }
      if (response.status === 401) {
        await eject('EXPIRED_TOKEN')
      }
    })
}

//period in minutes
export const checkToken = (period) => {
  SetInterval.start(
    async () => {
      const token = getToken()
      if (token) {
        try {
          const { exp } = jwt_decode(token)
          const tokenTime = dayjs(exp * 1000)
          const now = dayjs()
          const expireIn = tokenTime.diff(now, 'hours')
          if (expireIn < 2) {
            await refreshToken()
          }
        } catch (e) {
        }
      }
    },
    period * 1000 * 60,
    'tokenChecker'
  )
}

export const clearTokenChecker = () => {
  SetInterval.clear('tokenChecker')
}

export const canAccess = (user, page) => {
  switch (page) {
    case '/account':
      return isLoggedIn(user)
    default:
      break
  }

  return true
}

export const getFallBackPage = (page) => {
  switch (page) {
    default:
      break
  }

  return '/'
}
export const getCurrentUserRoles = () => {
  const token = getToken()
  if (!token) {
    return false
  }
  if (token) {
    const { roles } = jwt_decode(token)
    return roles
  }
  return []
}


export const hasRoles = (roles, givenUser = null) => {
  let userRoles = null
  if (givenUser) {
    if (!givenUser.roles) {
      return false
    }
    userRoles = givenUser.roles
  } else {
    userRoles = getCurrentUserRoles()
  }
  return _.intersection(roles, userRoles).length > 0
}

export const isAdmin = (givenUser = null) => {
  return hasRoles(['ROLE_ADMIN'], givenUser)
}
export const isContentManager = (givenUser = null) => {
  return hasRoles(['ROLE_CONTENT_MANAGER'], givenUser)
}
export const isUser = (givenUser = null) => {
  return hasRoles(['ROLE_USER'], givenUser)
}


export const ROLES = [
  {
    label: 'Administrator',
    value: 'ROLE_ADMIN'
  },
  {
    label: 'Content manager',
    value: 'ROLE_CONTENT_MANAGER'
  },
  {
    label: 'User',
    value: 'ROLE_USER'
  }
]
