import axios from 'axios'
import NProgress from 'nprogress'
import Router from 'next/router'
import { getToken, isPublic } from 'js/security'
import _ from 'lodash'
import qs from 'qs'
import memoize from 'fast-memoize'

export const eject = (reason) => {
  Router.push({
    pathname: '/logout',
    query: { reason }
  })
}

export const apiCall = async (props) => {
  const { uri, method = 'GET', params, raw, hydra = 'member', collection = true, headers, responseType, full } = props

  let token, auth
  if (!isPublic(uri, method)) {
    token = getToken()
    if (token) {
      auth = { Authorization: `Bearer ${token}` }
    }
  }

  const getParams = params && method === 'GET' ? `?${qs.stringify(params, { encodeValuesOnly: true })}` : ''

  return axios({
    url: process.env.NEXT_PUBLIC_API_URI + uri + getParams,
    method,
    data: params,
    responseType: responseType || 'json',
    headers: {
      'content-type': 'application/ld+json',
      ...headers,
      ...auth
    }
  })
    .then((response) => {
      if (raw) {
        return response
      }

      if (method === 'GET' && collection) {
        if (full) {
          return response.data
        }
        return response.data[`hydra:${hydra}`]
      }

      if (method === 'POST' || method === 'PUT' || !collection) {
        return response.data
      }
    })
    .catch((error) => {
      NProgress.done()
      // if (!init) {
      //   eject(error.response.data.message === 'Expired JWT Token' ? 'EXPIRED_TOKEN' : 'NOT_ALLOWED')
      // }
      return Promise.reject(error.response)
    })
}

export const getTotalItems = (entity, params) => {
  return apiCall({
    uri: entity,
    params,
    hydra: 'totalItems'
  })
    .then((items) => Promise.resolve(items))
    .catch((error) => Promise.reject(error))
}

export const getApiErrorMessage = (error) => {
  if (!error.response) {
    return `Erreur de connexion à l'API`
  }

  const errorMessage = [`Erreur code ${error.response.status}`]

  const url = error.response.config.url;
  const getUrlPath =  new URL(url).pathname
  if(getUrlPath === '/login'){
    return null
  }
  switch (error.response.status) {
    case 401: {
      errorMessage.push(error.response.data.message)
      if (_.includes(['Expired JWT Token', 'Invalid JWT Token', 'Invalid credentials.'], error.response.data.message)) {
        switch (error.response.data.message) {
          case 'Invalid credentials.':
            if (getToken()) {
              console.log('eject')
              eject('NOT_ALLOWED')
            }
            break
          default:
            eject('EXPIRED_TOKEN')
            break
        }
      }
      if (error.response.data.message === 'JWT Token not found') {
        Router.push('/')
        return null
      }

      break
    }
    case 404: {
      errorMessage.push(`Ressource non trouvée`)
      break
    }
    case 400:
    case 403:
    case 422: {
      errorMessage.push(
        error.response.data.detail ? error.response.data.detail : error.response.data['hydra:description']
      )
      break
    }
    case 500: {
      errorMessage.push('Application')
      break
    }
    default:
      errorMessage.push(error.response.statusText)
      break
  }
  return errorMessage.join(' ')
}

export const getResponseTotalItems = (response) => {
  return _.get(response, ['hydra:totalItems'], 0)
}

export const getPages = (response) => {
  const [next, previous, first, last] = ['next', 'previous', 'first', 'last'].map((param) => {
    const url = _.get(response, ['hydra:view', `hydra:${param}`], '')
      .split('?')
      .pop()

    const { page } = qs.parse(url)
    return parseInt(page, 10) || null
  })

  return {
    ...{
      next,
      previous,
      first,
      last,
      total: getResponseTotalItems(response)
    }
  }
}

export const getApiWordDefinition = memoize((word) => {
  return axios.get(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`).then((response) => response.data)
})
