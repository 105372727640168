import { apiCall } from 'js/api'

export const API_login = (data) => {
  return apiCall({
    uri: 'login',
    method: 'POST',
    params: data,
    raw: true
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}
export const API_register = (data) => {
  return apiCall({
    uri: 'users',
    method: 'POST',
    params: data,
    raw: true
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}
