import React from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './Title.module.scss'
import { useWidth } from "js/hook";

function Title({ options, componentClass, children }) {

  const width = useWidth();

  const { h } = options
  return (
    <Typography className={styles[`title_h${h}`]} variant={`h${h}`}>
      <span className={clsx([componentClass, width])}>{children}</span>
    </Typography>
  )
}

export default Title
