import update from 'immutability-helper'
import _ from 'lodash'

const initialState = []

const wordListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_WORD_LISTS': {
      return update(state, { $set: action.payload })
    }
    case 'UPDATE_WORD_LIST_STATUS': {
      const { status } = action.payload
      const index = _.findIndex(state, { ['@id']: action.payload['@id'] })
      return update(state, { [index]: { status: { $set: status } } })
    }
    case 'ADD_WORD_LIST': {
      return update(state, { $push: [action.payload] })
    }
    case 'DELETE_WORD_LIST': {
      const index = _.findIndex(state, { ['@id']: action.payload })
      return update(state, { $splice: [[index, 1]] })
    }
    default:
      return state
  }
}

export default wordListReducer
