import { createTheme } from '@mui/material/styles'

const palette = {
  common: {
    black: '#000',
    white: '#fff'
  },
  background: {
    paper: 'rgba(255, 255, 255, 1)',
    default: '#fafafa'
  },
  primary: {
    main: '#00204A'
  },
  secondary: {
    main: '#FF790F',
    dark: '#b2540a',
    light: '#ff933f',
    contrastText: '#fff'
  },
  ternary: {
    main: '#00689D',
    light: '#334c6e',
    dark: '#001633',
    contrastText: '#fff'
  },
  error: {
    main: '#f44336'
  },
  text: {
    primary: '#C4C4C4'
  }
}

const theme = createTheme({
  palette,
  components: {
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        fullWidth: true,
        variant: 'outlined'
      }
    },
    MuiSelect: {
      defaultProps: {
        margin: 'dense',
        fullWidth: true,
        variant: 'outlined'
      }
    },
    MuiChip: {
      defaultProps: {
        variant: 'default'
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: palette.common.black
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: 14
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#E8F2F2'
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: 0,
          marginRight: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          fontSize: 16
        },
        outlinedSecondary: {
          fontSize: 16
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: palette.common.black,
          fontSize: 14
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: palette.primary.main
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#E8F2F2'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: '#E8F2F2'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
        h1: {
          fontSize: 48,
          paddingTop: 16,
          paddingBottom: 16,
          color: palette.primary.main
        },
        h2: {
          fontSize: 36,
          color: palette.secondary.main,
          paddingTop: 22,
          paddingBottom: 22
        }
      }
    }
  }
})

export default theme
