import React, { useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import qs from 'qs'
import Router from 'next/router'

function SearchInput({ entity, destination, fields }) {
  const [query, setQuery] = useState('')

  const search = () => {
    const params = fields.reduce(
      (ac, a) => ({
        ...ac,
        [a]: query
      }),
      {}
    )
    query && Router.push(`${destination}?${qs.stringify(params, { encodeValuesOnly: true })}`)
  }

  return (
    <TextField
      placeholder={'Search a media'}
      name={'search'}
      type="search"
      onChange={(event) => setQuery(event.target.value)}
      onKeyPress={(e) => e.key === 'Enter' && search()}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" onClick={search} style={{ cursor: 'pointer' }}>
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchInput
