import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useRouter } from 'next/router'
import LoginModal from 'components/modal/LoginModal'
import Header from './Header'
import LeftBar from './LeftBar'

const drawerWidth = 340
const drawerWidthSm = 240

const layoutConfig = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthSm,
      flexShrink: 0
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidth
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidthSm}px)`,
      marginLeft: drawerWidthSm,
      background: 'white',
      color: theme.palette.primary.main,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12);'
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: { minHeight: 91 },
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthSm
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidth
    }
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
      width: `calc(100% - ${drawerWidthSm}px)`
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 6),
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 16),
      width: `calc(100% - ${drawerWidth}px)`
    }
  }
}))

function Layout({ children }) {
  const router = useRouter()
  const classes = layoutConfig()

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  //auto close menu on change url
  useEffect(() => {
    mobileOpen && setMobileOpen(false)
  }, [router])

  return (
    <div className="app">
      <Header classes={classes} handleDrawerToggle={handleDrawerToggle} />
      <LeftBar classes={classes} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      <LoginModal />
    </div>
  )
}

export default Layout
