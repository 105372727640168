import { apiCall } from 'js/api'
import { getSlugId } from 'js/utils'

export const getCurrentUser = () => {
  return (dispatch) => {
    return apiCall({
      uri: 'me',
      collection: false
    })
      .then((user) => {
        if (user) {
          dispatch({
            type: 'SET_CURRENT_USER',
            payload: user
          })
          return user
        }
      })
      .catch((error) => console.log(error))
  }
}

export const logoutCurrentUser = () => {
  return (dispatch) => {
    dispatch({ type: 'LOGOUT_CURRENT_USER' })
  }
}

export const updateUser = (user) => {
  const userId = getSlugId(user)
  return (dispatch) => {
    return apiCall({
      uri: `users/${userId}`,
      method: 'PUT',
      params: user
    })
      .then((updatedUser) => {
        if (updatedUser) {
          dispatch({
            type: 'SET_CURRENT_USER',
            payload: updatedUser
          })
        }
      })
      .catch((error) => Promise.reject(error))
  }
}
