import React from 'react'
import { Divider, Grid, Hidden, List } from '@mui/material'
import SearchInput from 'components/commons/input/SearchInput'
import Link from 'components/commons/Link'
import UserPseudo from 'components/layout/UserPseudo'
import clsx from 'clsx'
import Image from 'components/commons/Image'
import { useWidth } from 'js/hook'
import { RenderIfAuthorized } from 'components/commons/RenderIfAuthorized'

function Menu({ classes }) {
  const width = useWidth()
  return (
    <div className={clsx(['left', width])}>
      <Hidden smUp>
        <div className={`${classes.toolbar} logo`}>
          <Link href="/">
            <Image src="/images/logo.svg" alt={'logo'} width={135} height={43} />
          </Link>
        </div>
      </Hidden>
      <div className="searchbar">
        <SearchInput entity={'items'} fields={['name']} destination={'/items/list'} />
      </div>
      <List className="menu">
        <li>
          <Link href="/items/list">Explore</Link>
        </li>
        <RenderIfAuthorized roles={['ROLE_USER']}>
          <li>
            <Link href="/groups/list">My media</Link>
          </li>
        </RenderIfAuthorized>
        <RenderIfAuthorized roles={['ROLE_CONTENT_MANAGER', 'ROLE_USER']}>
          <li>
            <Link href="/account/wordList">Revise</Link>
          </li>
        </RenderIfAuthorized>
        <div className="separator">
          <Divider />
        </div>
        <li>
          <Link href="/about-us">About us</Link>
        </li>
        <li>
          <Link href="/our-mission">Our mission</Link>
        </li>
      </List>
      <Hidden smUp>
        <div className="separator">
          <Divider />
        </div>
        <Grid container className="user" spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
          <Grid>
            <Divider />
          </Grid>
          <Grid className="language" item>
            <Image src="/images/flags/en.svg" alt={'en'} width={28} height={28} />
          </Grid>
          <Grid className="icon" item>
            <Image src="/images/account.svg" alt={'account'} width={23} height={28} />
          </Grid>
          <Grid className="pseudo" item xs={8}>
            <UserPseudo />
          </Grid>
        </Grid>
        <div className="separator">
          <Divider />
        </div>
        <Grid container className="qrcode" spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
          <Grid>
            <Image src="/images/qrcodescan.png" alt={'qrcode'} width={80} height={80} />
          </Grid>
          <Grid>Scan your Skreept QrCode</Grid>
        </Grid>
      </Hidden>
    </div>
  )
}

export default Menu
