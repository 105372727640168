import { apiCall } from 'js/api'
import { getSlugId } from 'js/utils'



export const API_getUserWordLists = (params, options) =>
  apiCall({
    uri: 'user_word_lists',
    params,
    ...options
  })
    .then((item) => Promise.resolve(item))
    .catch((error) => Promise.reject(error))


export const Api_updateUserWordList = (userWordList) => {
  const id = getSlugId(userWordList)

  return apiCall({
    uri: `user_word_lists/${id}`,
    method: 'PUT',
    params: userWordList
  })
    .then((result) => Promise.resolve(result))
    .catch((error) => Promise.reject(error))
}

export const API_addUserWordList = (userWordList) => {
  return apiCall({
    uri: `user_word_lists`,
    method: 'POST',
    params: userWordList
  })
    .then((result) => Promise.resolve(result))
    .catch((error) => Promise.reject(error))
}



export const API_deleteUserWordList = (userWordList) => {
  const id = getSlugId(userWordList)
  return apiCall({
    uri: `user_word_lists/${id}`,
    method: 'DELETE'
  })
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error))
}


export const API_getUserWordListsPdf = (params, options) => {
  return API_getUserWordLists(
    { ...params, ...{ toPdf: true } },
    {
      ...options,
      ...{
        responseType: 'blob',
        raw: true
      }
    }
  )
}
