import React, { useState } from 'react'
import { toggleModal } from 'store/actions/modalActions'
import { Button, Dialog, DialogContent, IconButton, InputAdornment } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Title from 'components/commons/typo/Title'
import { useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import { isAdmin, setToken } from 'js/security'
import { getCurrentUser } from 'store/actions/currentUserActions'
import { pushNoty } from 'store/actions/notyActions'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { API_login, API_register } from 'js/api/Users'
import ControllerText from 'components/commons/input/ControllerText'
import styles from './LoginModal.module.scss'
import { EMAIL_REGEX } from 'config/settings'
import { getUserWordLists } from 'store/actions/wordListActions'
import { eject } from 'js/api'

function LoginModal() {
  const dispatch = useDispatch()
  const loginModal = useSelector((state) => state.modal.login)
  const [loginForm, setLoginForm] = useState(true)
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    getValues
  } = useForm()
  const [showPassword, setShowPassword] = useState(false)

  const connect = async (form) => {
    API_login(form)
      .then((response) => handleUserConnection(response))
      .catch(() => {
        dispatch(
          pushNoty({
            message: 'Identifiant ou mot de passe incorrect',
            severity: 'error'
          })
        )
      })
  }

  const registration = async (form) => {
    await API_register(form)
      .then(() => connect(form))
      .catch((error) =>
        dispatch(
          pushNoty({
            message: `Account creation error ${error}`,
            severity: 'error'
          })
        )
      )
  }

  const handleUserConnection = async (response) => {
    if (response.status === 200) {
      await setToken('token', response.data.token)
      await setToken('refresh_token', response.data.refresh_token)
      const currentUser = await dispatch(getCurrentUser())
      if (isAdmin(currentUser)) {
        await eject('NOT_USER')
        await dispatch(toggleModal('login'))
      } else {
        await dispatch(getUserWordLists())
        await dispatch(
          pushNoty({
            message: 'You are connected',
            severity: 'info'
          })
        )
        await dispatch(toggleModal('login'))
      }
    } else {
      await dispatch(
        pushNoty({
          message: 'Token error',
          severity: 'error'
        })
      )
    }
  }

  const form = {
    title: loginForm ? 'Log in' : 'Create an account',
    submitButtonText: loginForm ? 'Log in' : 'Register',
    formTogglerButtonText: loginForm ? 'I do not have an account' : 'I already have an account',
    formTogglerButtonAction: loginForm ? () => setLoginForm(false) : () => setLoginForm(true),
    formAction: loginForm ? connect : registration
  }

  const onClose = () => {
    dispatch(toggleModal('login'))
  }

  return (
    <Dialog
      open={loginModal.open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles.login}
      maxWidth={'xs'}
      fullWidth
    >
      <DialogContent>
        <IconButton aria-label="close" className={'close'} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
        <Title options={{ h: 1 }}>{form.title}</Title>
        <div className={'form'}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(form.formAction)}>
            {!loginForm && (
              <div className={'pseudo'}>
                <ControllerText
                  control={control}
                  label="Pseudo"
                  name={'pseudo'}
                  disabled={isSubmitting}
                  error={!!errors.pseudo}
                  rules={{
                    required: true
                  }}
                />
              </div>
            )}
            <div className={'email'}>
              <ControllerText
                control={control}
                label="Email"
                name={'email'}
                disabled={isSubmitting}
                error={!!errors.email}
                rules={{
                  required: true,
                  pattern: EMAIL_REGEX
                }}
              />
            </div>
            <div className={'password'}>
              <ControllerText
                label="Password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                name={'password'}
                disabled={isSubmitting}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                rules={{
                  required: true,
                  minLength: 5
                }}
                control={control}
                helperText={'Requis / 5 caractères mini'}
              />
            </div>
            {!loginForm && (
              <div className={'password2'}>
                <ControllerText
                  control={control}
                  label="Password confirmation"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  name={'password2'}
                  disabled={isSubmitting}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  rules={{
                    validate: (value) => value === getValues().password
                  }}
                  helperText={'Les mots de passe doivent correspondre'}
                />
              </div>
            )}
            <div className={'__aligned_center buttons'}>
              <div className="login">
                <Button type="submit" variant="contained" color="secondary" disableElevation disabled={isSubmitting}>
                  {form.submitButtonText}
                </Button>
              </div>
              <div className="button">
                <Button
                  variant={'outlined'}
                  onClick={form.formTogglerButtonAction}
                  color="secondary"
                  disableElevation
                  disabled={isSubmitting}
                >
                  {form.formTogglerButtonText}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default LoginModal
