import Image from 'next/image'
import React from 'react'

export default React.forwardRef((props, ref) => {
  const { src, alt, width, height, ...rest } = props

  return (
    <span ref={ref} {...rest}>
      <Image src={src} alt={alt} width={width} height={height} />
    </span>
  )
})
