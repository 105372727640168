import Router from 'next/router'

export const getPageTitle = () => {
  switch (Router.router.pathname) {
    case '/item/[id]':
      switch (Router.router.query.id) {
        case 'new':
          return 'Nouveau support'
        default:
          return 'Édition support'
      }
    case '/item/list':
      return 'Liste des supports'
    case '/users':
      return 'Gestion des utilisateurs'
    default:
      return 'Mon tableau de bord'
  }
}

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
