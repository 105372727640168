import React from 'react'
import Link from 'components/commons/Link'

const ButtonLink = React.forwardRef(({ className, href, hrefAs, children, prefetch, onClick }, ref) => (
  <Link
    href={href}
    as={hrefAs}
    prefetch={prefetch}
    ref={ref}
    className={className}
    onClick={(e) => {
      if (onClick) {
        e.preventDefault()
        onClick()
      }
    }}
  >
    {children}
  </Link>
))

export default ButtonLink
